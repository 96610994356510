import React, { forwardRef, useEffect, useRef, useState } from "react";

import { ReactComponent as ChevronDownIcon } from "../assets/icons/chevron-down.svg";
import { Heading } from "../components";
import faq from "../assets/faq";

const Faq = () => {
  const QuestionGroup = forwardRef(({ element, className }, ref) => {
    const [expand, setExpand] = useState(null);

    return (
      <div
        ref={ref}
        className={`flex flex-col border-t-[1px] border-solid border-[rgba(19,23,76,0.4)] py-[1em] font-heading text-black ${className}`}
      >
        <button type="button" onClick={() => setExpand(!expand)}>
          <div className="mb-[0.75em] flex items-start justify-between gap-[1em]">
            <h4>{element?.question}</h4>
            <div className="flex items-center gap-[0.75em] ease-in">
              <h4 className="hidden font-normal transition-linear md:block">
                {expand ? "ZWIŃ" : "ROZWIŃ"}
              </h4>
              <ChevronDownIcon
                width="0.75em"
                className={`mb-1 ${expand && "rotate-180"}`}
              />
            </div>
          </div>
        </button>

        <p
          className={`${expand ? "max-h-[4rem]" : "max-h-0"} overflow-hidden transition-all duration-500 ease-in-out`}
        >
          {element?.answer}
        </p>
      </div>
    );
  });

  QuestionGroup.displayName = "QuestionGroup";

  const questionsRef = useRef([]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("animate-slide-up");
          }
        });
      },
      {
        threshold: 0.5,
      },
    );

    const questionsCurrent = questionsRef.current;

    questionsCurrent.forEach((section) => {
      if (section) {
        observer.observe(section);
      }
    });

    return () => {
      questionsCurrent.forEach((section) => {
        if (section) {
          observer.unobserve(section);
        }
      });
    };
  }, []);

  return (
    <section id="faq" className="flex flex-col bg-gray">
      <div className="wrapper">
        <Heading text="Pytania pomocnicze" color="text-black" />
        <div className="flex flex-col">
          {faq?.map((element, index) => (
            <QuestionGroup
              element={element}
              key={index}
              ref={(el) => (questionsRef.current[index] = el)}
              className="slide-up-before"
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Faq;
