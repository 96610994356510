import React, { forwardRef, useEffect, useRef, useState } from "react";

import { ReactComponent as ArrowDownIcon } from "../assets/icons/arrow-down.svg";
import { Heading } from "../components";
import { Link } from "react-router-dom";

const Products = () => {
  const Product = forwardRef(
    ({ name, title, image, description, className }, ref) => {
      const headingRef = useRef(null);
      const divRef = useRef(null);
      const [translateY, setTranslateY] = useState(0);
      const [hovered, setHovered] = useState(false);

      const [dimensions, setDimensions] = useState({
        height: window.innerHeight,
        width: window.innerWidth,
      });

      useEffect(() => {
        function handleResize() {
          setDimensions({
            height: window.innerHeight,
            width: window.innerWidth,
          });
        }

        window.addEventListener("resize", handleResize);

        return () => {
          window.removeEventListener("resize", handleResize);
        };
      });

      useEffect(() => {
        if (headingRef.current && divRef.current) {
          const headingHeight = headingRef.current.offsetHeight;
          const divHeight = divRef.current.offsetHeight;

          const divStyles = window.getComputedStyle(divRef.current);
          const paddingTop = parseFloat(divStyles.paddingTop);

          setTranslateY(divHeight - headingHeight - paddingTop);
        }
      }, [dimensions]);

      return (
        <div
          ref={ref}
          className={`relative h-[6rem] md:h-[19rem] md:last-of-type:odd:col-span-2 ${className}`}
        >
          <Link
            to={`/galeria#${name}`}
            className="absolute left-0 top-0 z-20 h-full w-full md:hidden"
          ></Link>

          <img
            src={require(`../assets/images/${image}`)}
            alt={title}
            className="h-full w-full object-cover"
          />

          <div
            className="group absolute left-0 top-0 flex h-full w-full overflow-hidden transition-linear hover:bg-[rgba(0,0,0,0.6)]"
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
          >
            <div
              ref={divRef}
              className="flex h-full flex-col justify-between p-[2em] transition-linear md:px-[4em] md:py-[2em]"
              style={{
                transform: hovered
                  ? "translateY(0)"
                  : `translateY(${translateY}px)`,
              }}
            >
              <div className="flex h-full flex-col justify-between">
                <div className="flex flex-col">
                  <h3 ref={headingRef} className="pb-[0.25em] text-shadow">
                    {title}
                  </h3>
                  <p className="hidden md:block">{description}</p>
                </div>
                <Link to={`/galeria#${name}`}>
                  <div className="group/link flex items-center gap-[0.5em]">
                    <p className="font-heading font-bold">Zobacz galerię</p>
                    <ArrowDownIcon
                      className="-rotate-90 transition-linear group-hover/link:translate-x-2"
                      height="1.5em"
                    />
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      );
    },
  );

  Product.displayName = "Product";

  const productsRef = useRef([]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("animate-slide-up");
          }
        });
      },
      {
        threshold: 0.5,
      },
    );

    const productsCurrent = productsRef.current;

    productsCurrent.forEach((section) => {
      if (section) {
        observer.observe(section);
      }
    });

    return () => {
      productsCurrent.forEach((section) => {
        if (section) {
          observer.unobserve(section);
        }
      });
    };
  }, []);

  return (
    <section id="products" className="relative">
      <div className="wrapper">
        <Heading text="Produkty" color="text-white" />

        <div className="grid gap-[1em] text-white md:grid-cols-2">
          <Product
            ref={(el) => (productsRef.current[0] = el)}
            className="slide-up-before"
            name="kostka_brukowa"
            title="Kostka brukowa"
            image="paving-stones-img.webp"
            description="Oferujemy kostkę brukową w różnych kolorach i wzorach, jest odporna na warunki atmosferyczne, co gwarantuje długowieczność i łatwą pielęgnację. To trwałe i estetyczne rozwiązanie do wykończenia nawierzchni wokół domu, ogrodu czy podjazdu."
          />

          <Product
            ref={(el) => (productsRef.current[2] = el)}
            className="slide-up-before"
            name="kruszywa"
            title="Kruszywa"
            image="aggregate-img.webp"
            description="Dostarczamy wysokiej jakości kruszywa, które znajdują zastosowanie w budownictwie drogowym, budownictwie mieszkaniowym oraz w ogrodach. Nasze kruszywa są starannie selekcjonowane, co zapewnia ich wysoką jakość i trwałość."
          />

          <Product
            ref={(el) => (productsRef.current[3] = el)}
            className="slide-up-before md:md:animation-delay-500"
            name="piasek_dekoracyjny"
            title="Piasek dekoracyjny"
            image="decorative-sand-img.webp"
            description="Zapewniamy wysokiej jakości piasek dekoracyjny, idealny do ogrodów, alejek i przestrzeni publicznych. Nasz piasek jest starannie selekcjonowany, co zapewnia jego estetykę i trwałość, doskonale uzupełniając każdy projekt aranżacji przestrzeni."
          />

          <Product
            ref={(el) => (productsRef.current[1] = el)}
            className="slide-up-before md:md:animation-delay-500"
            name="kamienie_dekoracyjne"
            title="Kamienie dekoracyjne"
            image="decorative-stones-img.webp"
            description="W naszej ofercie posiadamy kamienie dekoracyjne, idealne do aranżacji ogrodów, rabat i oczek wodnych. Wybierz spośród różnych kolorów i kształtów, aby nadać swojej przestrzeni unikalny charakter."
          />

          <Product
            ref={(el) => (productsRef.current[4] = el)}
            className="slide-up-before"
            name="ogrodzenia_panelowe"
            title="Ogrodzenia panelowe"
            image="panel-fencing-img.webp"
            description="W naszej ofercie znajdziesz solidne i estetyczne ogrodzenia panelowe, idealne do zabezpieczenia posesji. Trwałe i łatwe w montażu panele dostępne są w różnych wysokościach oraz kolorach, co pozwala na ich dopasowanie do każdego otoczenia."
          />

          <Product
            ref={(el) => (productsRef.current[5] = el)}
            className="slide-up-before md:md:animation-delay-500"
            name="ogrodzenia_modułowe"
            title="Ogrodzenia modułowe"
            image="modular-fencing-img.webp"
            description="Oferujemy nowoczesne ogrodzenia modułowe, które doskonale łączą funkcjonalność z estetyką. Dostępne w różnych stylach i kolorach, zapewniają nie tylko solidne zabezpieczenie, ale także elegancki wygląd, który harmonijnie wpisuje się w każde otoczenie."
          />

          <Product
            ref={(el) => (productsRef.current[6] = el)}
            className="slide-up-before"
            name="beton"
            title="Beton"
            image="concrete-img.webp"
            description="Oferujemy wysokiej jakości beton do budownictwa drogowego, mieszkaniowego i przemysłowego. Nasz starannie formułowany beton zapewnia wysoką wytrzymałość i trwałość, idealny do realizacji nawet najbardziej wymagających projektów."
          />

          <Product
            ref={(el) => (productsRef.current[7] = el)}
            className="slide-up-before md:md:animation-delay-500"
            name="chemia"
            title="Chemia"
            image="chemicals-img.webp"
            description="Dostarczamy wysokiej jakości środki chemiczne do pielęgnacji kostki brukowej, idealne do przestrzeni publicznych i prywatnych. Nasze produkty skutecznie czyszczą, impregnują i chronią kostkę, zapewniając jej trwałość i estetyczny wygląd."
          />
        </div>
      </div>

      <div className="absolute left-0 top-0 -z-10 grid h-full w-full grid-rows-2 overflow-hidden">
        <div
          className="absolute left-0 top-[-25vh] h-full w-full -skew-y-6"
          style={{
            backgroundImage: `linear-gradient(rgba(0, 3, 39, 0.75), rgba(0, 3, 39, 0.75)), url(${require("../assets/backgrounds/bg-img-2.webp")}`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            filter: "blur(2px)",
            boxShadow: "0 0 0 4px rgba(0, 0, 18, 0.7)",
          }}
        ></div>

        <div
          className="absolute left-0 top-[50%] h-full w-full -skew-y-6"
          style={{
            backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.75), rgba(255, 255, 255, 0.75)), url(${require("../assets//backgrounds/bg-img-1.webp")}`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        ></div>
      </div>
    </section>
  );
};

export default Products;
