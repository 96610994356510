import "./index.scss";

import App from "./App";
import { BrowserRouter } from "react-router-dom";
import React from "react";
import { ScrollToTop } from "./components";
import { createRoot } from "react-dom/client";

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <BrowserRouter basename="">
      <ScrollToTop />
      <App />
    </BrowserRouter>
  </React.StrictMode>,
);
