const catalogs = [
  {
    company: "Kefal",
    catalog:
      "https://kefal.pl/wp-content/uploads/2024/05/84658-kefal-kostka-brukowa-katalog.pdf",
  },
  {
    company: "Joniec",
    catalog: "https://joniec.pl/do-pobrania/katalog-2024/1787",
  },
  {
    company: "Superbruk",
    catalog:
      "https://www.superbruk.com.pl/wp-content/uploads/2023/08/superbruk_PL23_small.pdf",
  },
  {
    company: "Kamal",
    catalog:
      "https://kamal.pl/wp-content/uploads/2023/12/Kamal_katalog_2023_205x290mm.pdf",
  },
  {
    company: "Superbet",
    catalog:
      "https://www.superbet.com.pl/wp-content/uploads/2024/02/superbet-katalog-2024-wersja-online.pdf",
  },
  {
    company: "Domelo",
    catalog:
      "https://b2b.domelo.pl/wp-content/uploads/2024/02/Katalog-Domelo-2024.pdf",
  },
];

export default catalogs;
