import React, { useEffect, useState } from "react";

import { ReactComponent as ArrowDownIcon } from "../assets/icons/arrow-down.svg";
import { ReactComponent as LisText } from "../assets/icons/lis.svg";
import scrollToSection from "../functions/scrollToSection";

const Hero = () => {
  const [fontsLoaded, setFontsLoaded] = useState(false);
  const [loaded, setLoaded] = useState(false);

  const handleImageLoad = () => {
    setLoaded(true);
  };

  useEffect(() => {
    const checkFont = async () => {
      await document.fonts.ready;

      const k2d = document.fonts.check("16px 'K2D'");
      const inter = document.fonts.check("16px 'Inter'");

      setFontsLoaded(k2d && inter);
    };

    checkFont();
  }, []);

  return (
    <section id="hero" className="hero relative min-h-screen">
      <div className="absolute left-0 top-0 h-full w-full">
        <img
          src={require("../assets/backgrounds/hero-img.webp")}
          onLoad={handleImageLoad}
          alt="Hero"
          className={`h-full w-full object-cover blur-[1.5px] ${loaded ? "block" : "hidden"}`}
        />
        <div className="hero-img"></div>
      </div>

      <div className="absolute left-0 top-0 z-10 grid h-full w-full animate-slide-up  grid-rows-[80%_20%] place-items-center ease-in-out md:grid-rows-[auto_min-content]">
        <div className="flex w-[80vw] flex-col lg:w-[50vw]">
          <div className="my-[2.5em] flex flex-col items-center justify-center font-bold text-white text-shadow">
            <LisText className="brand-text" />
            <h2
              className={`text-center ${!fontsLoaded ? "fonts-loaded font-primary" : "font-primary-fallback"}`}
            >
              Galeria kostki brukowej
            </h2>
            <hr
              className="h-[0.125em] w-[70%] bg-red lg:w-[40%]"
              style={{ width: "clamp(200px, 40%, 500px)" }}
            />
            <h3
              className={`mb-1 ${!fontsLoaded ? "fonts-loaded font-primary" : "font-primary-fallback"}`}
            >
              Materiały budowlane
            </h3>
            <p
              className={`font-normal ${!fontsLoaded ? "fonts-loaded font-primary" : "font-primary-fallback"}`}
            >
              Tel. 664 555 003
            </p>
          </div>
          <p
            className={`hero-p text-center font-bold text-white text-shadow ${!fontsLoaded ? "fonts-loaded font-heading" : "font-heading-fallback"}`}
          >
            Solidna jakość, doskonałe doradztwo, wybierz ofertę dla siebie!{" "}
          </p>
        </div>
        <ArrowDownIcon
          fill="white"
          className="z-10 h-[6vh] w-auto cursor-pointer self-start transition-linear hover:translate-y-[1em] md:mb-[10vh]"
          onClick={scrollToSection("intro")}
        />
      </div>
    </section>
  );
};

export default Hero;
