import React, { useEffect, useRef } from "react";

const Intro = () => {
  const ProductIcon = ({ name, image }) => {
    return (
      <div className="grid w-[6em] grid-rows-[3em_min-content] place-items-center gap-[0.5em] text-center transition-linear hover:scale-[1.15]">
        <img
          src={require(`../assets/images/${image}`)}
          alt={name}
          className="max-h-[3em]"
        />
        <p className="font-bold text-dark-red">{name}</p>
      </div>
    );
  };

  const sectionsRef = useRef([]);
  const imagesRef = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("animate-slide-up");
          }
        });
      },
      {
        threshold: 0.5,
      },
    );

    const sectionsCurrent = sectionsRef.current;
    const imagesCurrent = imagesRef.current;

    sectionsCurrent.forEach((section) => {
      if (section) {
        observer.observe(section);
      }
    });

    if (imagesCurrent) {
      observer.observe(imagesCurrent);
    }

    return () => {
      sectionsCurrent.forEach((section) => {
        if (section) {
          observer.unobserve(section);
        }
      });

      if (imagesCurrent) {
        observer.unobserve(imagesCurrent);
      }
    };
  }, []);

  return (
    <section
      id="intro"
      style={{
        backgroundImage: `linear-gradient(rgba(238, 238, 238, 0.95), rgba(238, 238, 238, 0.95)), url(${require("../assets/backgrounds/bg-img-1.webp")}`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className="grid gap-[3em] wrapper lg:grid-cols-2 lg:gap-[4em]">
        <div className="flex flex-col gap-[3em] text-center">
          <div
            className="flex flex-col slide-up-before"
            ref={(el) => (sectionsRef.current[0] = el)}
          >
            <div className="mb-[2em] flex flex-col items-center justify-center">
              <h3 className="mb-[0.25em]">Witaj w Naszej Galerii!</h3>
              <hr className="h-[0.25em] w-[40%] bg-red" />
            </div>

            <p>
              Zapraszamy do odwiedzenia naszej galerii, gdzie znajdziesz
              największy wybór produktów, które spełnią Twoje oczekiwania.
              Oferujemy szeroką gamę kruszyw, kamieni dekoracyjnych, kostki
              brukowej oraz ogrodzeń. Nasze produkty są idealne do wszelkiego
              rodzaju projektów budowlanych i dekoracyjnych. Ponadto, w naszej
              ofercie znajdziesz specjalistyczne środki chemiczne do
              czyszczenia, konserwacji i pielęgnacji kostki brukowej.
            </p>
          </div>

          <div
            className="flex flex-col slide-up-before md:animation-delay-500"
            ref={(el) => (sectionsRef.current[1] = el)}
          >
            <div className="mb-[2em] flex flex-col items-center justify-center">
              <h3 className="mb-[0.25em]">
                Nasza Kompleksowa Oferta: Od Zakupu do Realizacji
              </h3>
              <hr className="h-[0.25em] w-[40%] bg-red" />
            </div>

            <p>
              U nas znajdziesz wszystko, czego potrzebujesz – od materiałów po
              ich transport i fachowy montaż. Dzięki synergii między naszą firmą
              zajmującą się sprzedażą kostki brukowej, kruszyw, chemii, betonu i
              wszystkiego czego potrzebujesz oraz firmą Bruk-Mar specjalizującą
              się w układaniu i montażu, możesz mieć pewność, że Twój projekt
              będzie przebiegał sprawnie i bezproblemowo.
            </p>
          </div>

          <div
            className="flex flex-col slide-up-before md:animation-delay-750"
            ref={(el) => (sectionsRef.current[2] = el)}
          >
            <div className="mb-[2em] flex flex-col items-center justify-center">
              <h3 className="mb-[0.25em]">
                Realizuj Swoje Projekty Bez Wysiłku
              </h3>
              <hr className="h-[0.25em] w-[40%] bg-red" />
            </div>

            <p>
              Zaufaj naszemu doświadczeniu i kompleksowej ofercie, aby
              zrealizować swoje projekty budowlane bez zbędnego wysiłku.
              Skontaktuj się z nami, aby uzyskać więcej informacji, omówić
              szczegóły projektu i rozpocząć współpracę z profesjonalistami,
              którzy z pasją i zaangażowaniem podejdą do Twojej inwestycji.
            </p>
          </div>
        </div>

        <div
          ref={imagesRef}
          className="grid grid-cols-3 gap-[1em] slide-up-before"
        >
          <img
            src={require("../assets/images/intro-img-1.webp")}
            alt="Kostka brukowa"
            className="col-span-2 h-full object-cover pt-[2em]"
          />

          <img
            src={require("../assets/images/intro-img-2.webp")}
            alt="Kostka brukowa"
            className="h-full object-cover"
          />

          <img
            src={require("../assets/images/intro-img-3.webp")}
            alt="Kostka brukowa"
            className="h-full object-cover"
          />

          <img
            src={require("../assets/images/intro-img-4.webp")}
            alt="Kostka brukowa"
            className="col-span-2 h-full object-cover pb-[2em]"
          />
        </div>

        <div
          ref={(el) => (sectionsRef.current[3] = el)}
          className="mx-auto my-0 flex w-full flex-wrap justify-around gap-[2em] slide-up-before md:animation-delay-500 lg:col-span-2 lg:w-[90%]"
        >
          <ProductIcon name="Kostka brukowa" image="paving-stones.webp" />
          <ProductIcon name="Kruszywa" image="aggregate.webp" />
          <ProductIcon name="Piasek dekoracyjny" image="decorative-sand.webp" />
          <ProductIcon
            name="Kamienie dekoracyjne"
            image="decorative-stones.webp"
          />
          <ProductIcon name="Ogrodzenia" image="fencing.webp" />
          <ProductIcon name="Beton" image="concrete.webp" />
          <ProductIcon name="Chemia" image="chemicals.webp" />
        </div>
      </div>
    </section>
  );
};

export default Intro;
