import React, { forwardRef, useEffect, useRef } from "react";

import { Heading } from "../components";
import catalogs from "../assets/catalogs";

const Partnerships = () => {
  const Company = forwardRef(
    ({ name, logo, className, bg = "bg-white" }, ref) => {
      const catalog =
        (catalogs.find((obj) => obj?.company === name) || {})?.catalog || "";

      return (
        <div
          ref={ref}
          className={`align-center group relative flex h-[4rem] w-full justify-center max-lg:last-of-type:odd:col-span-2 max-lg:last-of-type:odd:w-[50%] md:h-full xl:last-of-type:odd:col-span-1 xl:last-of-type:odd:w-full ${bg} ${className}`}
        >
          <img
            src={require(`../assets/images/${logo}`)}
            alt={`${name} logo`}
            className="object-contain"
          />
          {catalog && (
            <div className="absolute left-0 top-0 flex h-full w-full items-center gap-[0.25em] bg-[rgba(0,0,0,0.8)] text-center opacity-0 transition-linear group-hover:opacity-100">
              <a
                href={`${catalog}`}
                target="_blank"
                rel="noopener noreferrer"
                className="partnership-link flex w-full items-center justify-center p-[0.5em] font-heading font-bold text-white transition-linear text-shadow"
              >
                Sprawdź katalog
              </a>
            </div>
          )}
        </div>
      );
    },
  );
  Company.displayName = "Company";

  const companiesRef = useRef([]);
  const divRef = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("animate-slide-up");
          }
        });
      },
      {
        threshold: 0.5,
      },
    );

    const divCurrent = divRef.current;
    const companiesCurrent = companiesRef.current;

    if (divCurrent) {
      observer.observe(divCurrent);
    }

    companiesCurrent.forEach((section) => {
      if (section) {
        observer.observe(section);
      }
    });

    return () => {
      if (divCurrent) {
        observer.unobserve(divCurrent);
      }

      companiesCurrent.forEach((section) => {
        if (section) {
          observer.unobserve(section);
        }
      });
    };
  }, []);

  return (
    <section
      id="partnerships"
      style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${require("../assets//backgrounds/bg-img-5.webp")}`,
        backgroundSize: "cover",
        backgroundPosition: "top",
        backgroundRepeat: "no-repeat",
      }}
      className="relative"
    >
      <div className="grid gap-[2em] wrapper md:grid-cols-2 md:gap-[12em]">
        <div className="z-10 flex flex-col justify-start">
          <Heading text="Współprace" color="text-white" />
          <p ref={divRef} className="text-white slide-up-before">
            Współpraca z wiodącymi firmami z różnych branż umożliwia nam
            dostarczanie najwyższej jakości produktów i usług. Dzięki naszym
            strategicznym partnerstwom mamy dostęp do najnowszych technologii i
            innowacyjnych rozwiązań, co pozwala nam oferować kompleksową obsługę
            oraz zaspokajać różnorodne potrzeby naszych klientów. Nasze
            partnerstwa zapewniają nie tylko wysoki standard wykonania, ale
            także efektywność w realizacji projektów, co przekłada się na pełne
            zadowolenie klientów i ich sukces. Korzystając z doświadczenia i
            ekspertyzy uznanych liderów branży, możemy gwarantować produkty i
            usługi, które spełniają najwyższe normy jakościowe i w pełni
            odpowiadają na oczekiwania naszych klientów.
          </p>
        </div>

        <div className="z-10 flex flex-col items-center justify-center">
          <div className="grid grid-cols-2 place-items-center gap-[1em] md:auto-rows-[12vh] lg:grid-cols-3">
            <Company
              ref={(el) => (companiesRef.current[0] = el)}
              className="slide-up-before"
              name="Kefal"
              logo="kefal-logo.webp"
            />

            <Company
              ref={(el) => (companiesRef.current[1] = el)}
              className="slide-up-before animation-delay-500"
              name="Joniec"
              logo="joniec-logo.webp"
            />

            <Company
              ref={(el) => (companiesRef.current[2] = el)}
              className="slide-up-before lg:animation-delay-1000"
              name="Superbruk"
              logo="superbruk-logo.webp"
            />

            <Company
              ref={(el) => (companiesRef.current[3] = el)}
              className="slide-up-before max-lg:animation-delay-500"
              name="Domelo"
              logo="domelo-logo.webp"
            />

            <Company
              ref={(el) => (companiesRef.current[4] = el)}
              className="slide-up-before lg:animation-delay-500"
              name="Kamal"
              logo="kamal-logo.webp"
            />

            <Company
              ref={(el) => (companiesRef.current[5] = el)}
              className="slide-up-before animation-delay-500 lg:animation-delay-1000"
              name="Feda"
              logo="feda-logo.webp"
            />

            <Company
              ref={(el) => (companiesRef.current[6] = el)}
              className="slide-up-before"
              name="CTS"
              logo="cts-logo.webp"
            />

            <Company
              ref={(el) => (companiesRef.current[7] = el)}
              className="slide-up-before animation-delay-500"
              name="SandBruk"
              logo="sandbruk-logo.webp"
              bg="bg-black"
            />

            <Company
              ref={(el) => (companiesRef.current[8] = el)}
              className="slide-up-before lg:animation-delay-1000"
              name="Superbet"
              logo="superbet-logo.webp"
            />
          </div>
        </div>

        <div className="absolute left-0 top-0 h-full w-full bg-[rgba(0,0,0,0.5)] md:w-[50%]"></div>
      </div>
    </section>
  );
};

export default Partnerships;
