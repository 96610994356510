import * as React from "react";

import {
  BrukMar,
  Contact,
  Faq,
  Gallery,
  Hero,
  Intro,
  Location,
  Partnerships,
  Products,
  Qualities,
} from "./sections";
import { Layout, Navbar } from "./components";
import { Route, Routes } from "react-router-dom";

import { Helmet } from "react-helmet";

function App() {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <Layout>
            <Helmet>
              <link
                rel="preload"
                href={require("../src/assets/backgrounds/hero-img.webp")}
                as="image"
                type="image/webp"
                crossOrigin
              />
              <link
                rel="preload"
                href={require("../src/assets/fonts/k2d-v11-latin_latin-ext-bold.woff2")}
                as="font"
                type="font/woff2"
                crossOrigin
              />
              <link
                rel="preload"
                href={require("../src/assets/fonts/inter-v18-latin_latin-ext-bold.woff2")}
                as="font"
                type="font/woff2"
                crossOrigin
              />
            </Helmet>
            <Hero />
            <Navbar />
            <Intro />
            <BrukMar />
            <Qualities />
            <Products />
            <Partnerships />
            <Contact />
            <Location />
            <Faq />
          </Layout>
        }
      />
      <Route
        path="/galeria"
        element={
          <Layout>
            <Navbar />
            <Gallery />
          </Layout>
        }
      />
    </Routes>
  );
}

export default App;
